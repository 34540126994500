<template>
  <section id="card-text-alignment">
    <section id="search-section">
      <b-row>
        <b-col md="12">
          <b-card>
            <Search></Search>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <section id="items">
      <b-row id="table-hover-row">
        <b-col md="12">
          <b-card>
            <div class="table-responsive">
              <Campaigns/>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </section>

  </section>
</template>

<script>
import Search from "@/views/hepsiburada/campaign/Search";
import Campaigns from "@/views/hepsiburada/campaign/Campaigns";

export default {
  name: "HepsiburadaCampaignHome",
  components: {Search,Campaigns}
}
</script>

<style scoped>

</style>
