<template>
  <b-overlay
      :show="show"
      rounded="sm"
  >
    <b-form @submit.prevent>
      <b-row>
        <b-col md="10">


        </b-col>
        <b-col md="2">
          <b-form-group label="Yönetim">
            <b-button-group size="sm">
              <b-button variant="primary" @click="getAll">Getir</b-button>
              <b-button variant="danger" @click="clearSearch">Temizle</b-button>
            </b-button-group>
          </b-form-group>
        </b-col>
      </b-row>


    </b-form>
  </b-overlay>
</template>

<script>
export default {
  name: "Search",
  data: () => ({
    show: false,
  }),
  methods: {
    getAll() {
      this.show = true
      this.$store.dispatch('hbCampaign/getCampaigns')
          .then(res => {
            this.show = false;
          })
          .catch(() => {
            this.show = false;
          })
    },
    clearSearch() {

    }
  }
}
</script>

<style scoped>

</style>
