<template>
  <div>
    {{ _campaigns }}
  </div>
  <!--
  <table class="table table-hover table-sm">
    <thead>
    <tr>
      <th>Tadarikçi</th>
      <th>T.Resim</th>
      <th>Ü.Resim</th>
      <th>Bilgi</th>
      <th>Adet</th>
      <th>Fiyat</th>
      <th>Termin</th>
    </tr>
    </thead>
    <tbody>
    <Product v-for="product in _supplierProducts" :key="product.id" :product="product"></Product>
    </tbody>
  </table>
  -->
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Campaigns",
  computed: {
    ...mapGetters('hbCampaign', ["_campaigns"]),
  }
}
</script>

<style scoped>

</style>
